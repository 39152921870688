.main-header {
  background-image: url("../../../public/gradient-bar.png");
  background-size: cover;
}

.main-header header {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 60px;
  color: #000;
  margin: auto;
  position: relative;
}

.header-title {
  font-weight: bold;
  margin-right: 20px;
  font-size: 1.25rem;
}

.header-title a {
  text-decoration: none;
  color: #000;
}

.tab-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 13px;
  padding: 3px 0 3px 3px;
}

.tab {
  font-size: 1em;
  margin: 0;
  padding: 5px 15px;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 3px;
}

.tab.selected, .tab.selected:hover {
  background-color: #000;
  color: #fff;
}

.tab:hover {
  background-color: #76c5d4;
  color: #fff;
}



.user-info {
  font-size: 1em;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.user-login {
  cursor: pointer;
}
.user-login:hover {
  text-decoration: underline;
}
.user-avatar {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}
.user-menu-icon {
  font-weight: bold;
  font-size: 1.5em;
  margin-left: 15px;
  cursor: pointer;
}
#credential_picker_container {
  top: 8px !important;
  right: 5px !important;
}


.context-menu {
  position: absolute;
  top: 50px; /* positions the menu above the user-info div */
  right: 0; /* aligns the menu to the right side of the user-info div */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.context-menu-item {
  color: black;
  font-size: 0.9em;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}
.context-menu-item.logout {
  border-top: 1px solid #ddd;
}
.context-menu-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}