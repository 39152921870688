// https://codepen.io/P1N2O/pen/pyBNzX
.animation {
  background: linear-gradient(60deg, #23a6d5, #23a6d5, #eec452, #ee7752, #e73c7e, #e73cd6);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}

.avatarUser {
  background-color: #ccc;
  background-size: cover;
  @extend .avatar;
}

.avatarAssistant {
  @extend .animation;
  @extend .avatar;
}

.avatar {
  border-radius: 50%;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin: 5px 10px 5px 5px;
}

.nameLabel {
  font-weight: bold;
  // margin-bottom: -10px;
}

.assistantName {
  text-decoration: none;
  color: #000;
}

.messageContainerUser {
  @extend .messageContainer;
  background-color: #fff;
}

.messageContainerAssistant {
  @extend .messageContainer;
  background-color: #f6f6f6;
}

.messageContainer {
  padding: 25px 15px; 
  display: flex;
  justify-content: center;

  .message {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .content {
      flex-grow: 1;
      margin-top: 5px;
      p {
        margin: 0;
      }
      table {
        font-size: 14px;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
        margin-top: 15px;
        padding: 0;
        tr {
          margin: 0;
          padding: 0;
          th, td {
            margin: 0;
            padding: 0;
            border: 1px solid #ccc;
            padding: 5px;
          }
        }
      }
    }

    .timestamp {
      margin-left: 20px; 
      color: #999;
      font-size: 12px;
      min-width: 100px;
    }
  }
}

.installExtensionButton {
  background: linear-gradient(to right, #338CF5, #43b5da);
  border: 2px solid #338CF5;
  color: white;
  padding: 5px 15px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 15px auto 0;
  cursor: pointer;
  border-radius: 15px;
}