.main-content::-webkit-scrollbar {
  width: 10px;
}
.main-content::-webkit-scrollbar-thumb {
  margin-top: 1px;
  background-color: #76c5d4;
  border-radius: 5px;
}
.main-content::-webkit-scrollbar-track {
  background-color: #eee;
}

.assistantMenuIcon {
  letter-spacing: 2px;
  display: inline-block;
  font-size: 22px;
  transform: scaleY(0.5);
  margin-left: 7px;
  cursor: pointer;
}

.container {
  display: flex;
  height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  position: relative;
  overflow-y: auto;
  background-color: #eee;
  padding-bottom: 200px;
}

.main-footer {
  background-color: #eee;
  position: absolute;
  bottom: 0;
  left: 0; /* left: 50px; */
  right: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #eee 50px), rgba(0,0,0,0);
}

.text-input {
  position: relative;
}

.text-input textarea {
  width: 800px;
  height: 100px;
  /* margin-top: 25px; */
  border-radius: 10px;
  border: none;
  padding: 15px;
  font-family: inherit;
  font-size: 1em;
  resize: none;
  outline: none;
}

.send-button {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  background-image: url('./send-button.png');
  background-size: contain;
}
.send-button:hover {
  background-image: url('./send-button-hover.png');
}

.actionHeader {
  position: sticky;
  top: 0;
  padding: 0 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
.actionHeaderInner {
  max-width: 1280px;
  margin: auto;
}

/* assistant */
.actionName {
  font-weight: bold;
  padding: 15px 8px 15px 0;
  font-size: 1.1rem;
  display: inline-block;
}

.betaPill {
  background-color: #338CF5;
  color: #fff;
  padding: 2px 5px;
  border-radius: 25px;
  font-size: 9px;
  vertical-align: middle;
  margin-bottom: 3px;
  display: inline-block;
}

/* action */
h1.actionName {
  line-height: 0.9;
  font-size: 35px;
  margin: 0;
  padding-top: 25px;
  display: block;
  text-align: center;
}

h1.actionName .betaPill {
  background-color: #338CF5;
  color: #fff;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 14px;
  vertical-align: middle;
  margin-left: 10px;
}

.actionDescription {
  font-size: 17px;
  color: #666;
  text-align: center;
  margin-bottom: 25px;
}