#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  font-size: 14px;
  margin-top: auto;
  padding: 25px;
  /* border-top: 1px solid #ccc; */
  width: 100%;
  background-color: #aed893;
}

footer span {
  display: inline-block;
  margin: 0 10px;
}

footer a {
  color: #000;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}