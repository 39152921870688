.hero {
  padding: 3rem 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.heroText {
  font-size: 5em;
  font-weight: 800;
  font-style: normal;
  margin-top: 0;
  background: linear-gradient(90deg, #338CF5, #4FD1C5);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heroText span {
  line-height: 0.9;
  display: inline-block;
}

.heroText span.taskName {
  -webkit-text-fill-color: #135db4;
}

.subline {
  font-weight: 300;
  color: #888;
  font-size: 1.75em;
  line-height: 1.3;
  font-style: normal;
  margin: 20px 0;
}

.signInGoogle {
  /* background: #4183f3; */
  color: #fff;
  border: none;
  padding: 12px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 0 auto;
  cursor: pointer;
  /* border-radius: 25px; */
  width: 175px;
  height: 38px;
  background-image: url('./sign-in-with-google.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.heroButton {
  text-align: center;
  margin: 0 auto 25px;
}

.content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  background: linear-gradient(to bottom, white, white 50%, #c1e7a7);
}

.link {
  color: #338CF5;
  text-decoration: none;
}

.login-button {
  width: 250px;
  margin: 0 auto;
}

.get-button {
  text-align: center;
}
.get-button a {
  background: linear-gradient(to right, #338CF5, #43b5da);
  border: 3px solid #338CF5;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 10px;
}
.get-button a.already-installed {
  background: #fff;
  color: #000;
  border: 1px solid #666;
}
.get-button a.already-installed:hover {
  background: #fff;
  cursor: arrow;
  color: #000;
}

.get-button a:hover {
  background: #338CF5;
  color: white;
  text-decoration: none;
}

.get-button-info {
  color: #666;
}

#openSidePanelContainer .name {
  font-size: 0.65em;
  font-weight: normal;
  margin-top: 0;
  display: none;
}
#openSidePanelContainer .launch {
  font-size: 1.25em;
}
.get-button a.openSidePanel {
  padding: 8px 24px 10px;
}

section.bg {
  width: 100%;
  color: #fff;
  background: linear-gradient(to top, #c1e7a7, #f7fff1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.video-container {
  position: relative;
  width: calc(100% - 100px);
  max-width: 960px;
  margin: 50px auto;
  height: 540px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 960px) {
  .video-container {
    width: calc(100% - 40px);
    height: 0;
    padding-top: 56.25%;
    margin-top: 20px;
  }
}

/* Add responsive padding adjustments */
@media (max-width: 768px) {
  .tab-bar {
    display: none;
  }
  .video-container {
    width: calc(100% - 20px);
    border-radius: 0;
  }
  .hero {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .user-name {
    display: none;
  }
  .hero .heroText {
    font-size: 2rem;
    line-height: 1.3;
  }
  
  .hero .subline {
    font-size: 1.1rem;
  }
}