$light: #fff;
$dark: #000;

.actionName {
  // text-align: left;
  line-height: 0.9;
  font-size: 45px;
  margin: 45px 0 0;
  .betaPill {
    background-color: #338CF5;
    color: #fff;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 14px;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.subline {
  // text-align: left;
  color: #333;
  font-size: 25px;
  line-height: 1;
  font-style: normal;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 10px;
}

h3 {
  margin-bottom: 5px;
  color: #338CF5;
  text-align: left;
}

.textarea {
  width: 100%;
  font-size: 24px;
  padding: 10px;
  border: 2px solid #000;
  margin-bottom: 25px;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 750px;
  margin: 0 auto;
  // height: 100vh;
}

.button {
  display: flex;
  justify-content: center;
}

.button button {
  background-color: $dark;
  border-radius: 25px;
  color: $light;
  border: 2px solid $dark;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

.button button:hover {
  background-color: $light;
  color: $dark;
}