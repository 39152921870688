.main-content::-webkit-scrollbar {
  width: 10px;
}
.main-content::-webkit-scrollbar-thumb {
  margin-top: 1px;
  background-color: #76c5d4;
  border-radius: 5px;
}
.main-content::-webkit-scrollbar-track {
  background-color: #eee;
}

.container {
  display: flex;
  height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}



.main-content {
  flex: 1;
  position: relative;
  overflow-y: auto;
  background-color: #eee;
  padding-bottom: 250px;
}

.main-footer {
  background-color: #eee;
  position: absolute;
  bottom: 0;
  left: 0; /* left: 50px; */
  right: 10px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #eee 50px), rgba(0,0,0,0);
}

.text-input {
  position: relative;
}

.text-input textarea {
  width: 800px;
  height: 100px;
  /* margin-top: 25px; */
  border-radius: 10px;
  border: none;
  padding: 15px;
  font-family: inherit;
  font-size: 1em;
  resize: none;
  outline: none;
}

.send-button {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  background-image: url('./send-button.png');
  background-size: contain;
}
.send-button:hover {
  background-image: url('./send-button-hover.png');
}
