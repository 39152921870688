$light: #adfbee;
$dark: #08947c;

h1 {
    margin: 80px 0 0;
    line-height: 1.125;
}

.tasks h2 {
  font-size: 16px;
}

h3 a {
  text-decoration: none;
  color: white;
}

.login-button {
    width: 250px;
    margin: 0 auto;
  }
  
  .get-button {
    text-align: center;
    margin: 50px 0 50px;
  }
  .get-button a {
    background: linear-gradient(to right, #338CF5, #4FD1C5);
    border: none;
    color: white;
    padding: 12px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    cursor: pointer;
    border-radius: 25px;
    width: 150px;
    margin-bottom: 10px;
  }
  .get-button a.already-installed {
    background: #fff;
    color: #000;
    border: 1px solid #666;
  }
  .get-button a.already-installed:hover {
    background: #fff;
    cursor: arrow;
    color: #000;
  }
  
  .get-button a:hover {
    background: #338CF5;
    color: white;
    text-decoration: none;
  }
  
  .get-button-info {
    color: #666;
  }
  

.subline {
    font-size: 40px;
    line-height: 1;
    font-style: normal;
    margin-top: 0;
    font-weight: normal;
    color: #999;
}

.task {
    background: linear-gradient(45deg, #08947c, #1bb096);
    width: 300px;
    height: 300px;
    margin: 25px 25px 0 0;
    color: $light;
    background-color: $dark;
    // border: 1px solid #90d0c5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
}

.inactive {
    filter: saturate(25%);
}

.taskInfo {
    padding: 25px;
    font-size: 20px;
    line-height: 1;
    font-style: normal;
    margin-top: 20px;  
    h3 {
        color: #fff;
        font-size: 32px;
    }
}

.tasksGrid {
    margin: 0 auto 100px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 30px;
}

.beginButton {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 30px;
}

.priceBanner {
    position: absolute;
    top: 25px;
    right: -5px;
    background-color: $light;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #000;
    padding: 5px 15px;
    font-size: 16px;
}
  
.beginButton button {
    background-color: rgb(15, 15, 152, 0);
    border-radius: 25px;
    color: $light;
    border: 1px solid $light;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
}
 
.beginButton button:hover {
    background-color: $light;
    color: $dark;
}